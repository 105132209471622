/* #region Fonts */
=font-face($family: Arial, $type, $weight:normal, $style:normal, $display:swap)
	@font-face
		font-family: $family
		src: local('#{$family}'), url('./fonts/#{$family}/#{$family}-#{$type}.woff2') format('woff2'), url('./fonts/#{$family}/#{$family}-#{$type}.woff') format('woff'),
		font-weight: $weight
		font-style: $style
		font-display: $display

+font-face('Exo2', 'Regular', 400)
+font-face('Exo2', 'Medium', 500)
+font-face('Exo2', 'Bold', 600)
+font-face('Exo2', 'ExtraBold', 700)

$base_font_size: 16px
$font_primary: 700 30px 'Exo2', sans-serif
$font_secondary: 500 $base_font_size 'Exo2', sans-serif
$font_other: 400 $base_font_size 'Exo2', sans-serif
/* #endregion */

/* #region Media */
$sm: 'screen and (max-width: 600px)'
$md: 'screen and (max-width: 960px)'
$lg: 'screen and (max-width: 1680px)'
$xl: 'screen and (max-width: 1920px)'
/* #endregion */

/* #region Palette */
$primary: #1C3C87
$secondary: #5C7E67
$other: #ffffff
/* #endregion */

/* #region Styles */
*
	outline: none

::-webkit-scrollbar
	width: 12px
	height: 12px
	@media #{$sm}
		width: initial
		height: initial

::-webkit-scrollbar-track
	background: $other

::-webkit-scrollbar-thumb
	background-color: #8CA394
	border-radius: 0

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active
	background: $secondary

body
	margin: 0
	padding: 0
	overflow: overlay
	font: $font_other
	background-color: $other
	color: $secondary
	scrollbar-width: thin
	scrollbar-color: #8CA394 $other

a
	cursor: pointer
	user-select: none
	color: $secondary
	text-decoration: underline
	transition: opacity 0.15s ease-in-out
	&:hover
		opacity: 0.8

.nowrap_txt
	display: block
	overflow: hidden
	white-space: nowrap
	text-overflow: ellipsis

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
	-webkit-appearance: none
	margin: 0

input[type='number']
	-moz-appearance: textfield

.swiper-container
	box-sizing: border-box
	padding: 0 0.5em

.swiper-pagination
	left: 50% !important
	text-align: center
	margin: 0 auto
	transform-origin: center
	transform: translateX(-50%)

.swiper-button-disabled,
.my-button-disabled
	opacity: 0.5 !important
	&:before
		background-color: none !important

.swiper-button-next:after,
.swiper-container-rtl,
.swiper-button-prev:after
	content: none !important
	outline: 0 !important

.swiper-button-prev,
.swiper-button-next
	img
		width: 100%
		height: auto

		outline: 0 !important

.swiper-button-prev
	left: 0

.swiper-button-next
	right: 0

.grecaptcha-badge
	display: none !important
	z-index: 100000
/* #endregion */

/* #region Animations */
@keyframes slideBgY
	0%
		background-position: top
	50%
		background-position: bottom
	100%
		background-position: top

@keyframes pulse
	from
		-webkit-transform: scale3d(1, 1, 1)
		transform: scale3d(1, 1, 1)
	50%
		-webkit-transform: scale3d(1.05, 1.05, 1.05)
		transform: scale3d(1.05, 1.05, 1.05)
	to
		-webkit-transform: scale3d(1, 1, 1)
		transform: scale3d(1, 1, 1)
/* #endregion */
